import React, { useState }  from 'react';
import axios from 'axios';
import ContactPage from '../images/ContactPage.mp4';
import ContactMain from '../images/ContactMain.png';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './Contact.css'; // Optional CSS for custom styling
import $ from 'jquery'; // Import jQuery
import 'jquery-confirm'; // Import jquery-confirm JavaScript
import 'jquery-confirm/css/jquery-confirm.css'; // Import jquery-confirm CSS

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const Contact = () => {

   // Define state variables for the form inputs
   const [name, setName] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');
   const [message, setMessage] = useState('');
   const [error, setError] = useState('');
   const messageLimit = 300;

   const showAlert = () => {
    $.alert({
      title: 'Success',
      content: 'Thank you! Your details have been submitted successfully. We will get back to you as soon as possible.',
      type: 'green',
    });
  }
 
   const handleSubmit = (e) => {
     e.preventDefault(); // Prevent the default form submission behavior
 
     // Basic validation logic
     if (!name || !email || !phone || !message) {
       setError('Please fill out all the fields before submitting.');
       return; // Stop submission if fields are empty
     }
     // Email Validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

      // Phone Validation (10 digits excluding country code)
      const phoneWithoutCountryCode = phone.replace(/[^0-9]/g, '').slice(-10); // Extract the last 10 digits
      if (phoneWithoutCountryCode.length !== 10) {
        setError('Phone number must be exactly 10 digits long (excluding country code)');
        return;
      }
 
     // If validation passes, clear error and proceed
   
    //alert('Form submitted successfully!');

    //Retrieve the list of emails from localStorage
    const storedEmails = JSON.parse(localStorage.getItem("emails")) || []; // Default to an empty array

    // Check if the email is already in the list
    if (storedEmails.includes(email)) {

        setError("You have recently sent a message. Please wait while we contact you back.");
        return; // Stop submission if fields are empty
    }
    setError('');
    // Add the email to the list
 
    // Send GET request with query parameters
    // Append data to URL as query parameters
axios.get('contact-submit/index.php', {
  params: {
    name: name,
    email: email,
    phone: "+"+phone,
    message: message
  }
})
  .then(response => {
    if (response.data.trim() === 'success') {
      showAlert()
      //alert('Form submitted successfully!');
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
      storedEmails.push(email);
      localStorage.setItem("emails", JSON.stringify(storedEmails)); // Save the updated list back to localStorage
    } else {
      setError('There was an error sending your message. Please try again later.');
    }
  })
  .catch(error => {
    setError('Error sending the message. Please try again later.');
  });

  };
  

  
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const handleVideoEnd = () => {
    setIsVideoEnded(true);

  };
  return (
    <>
      {/* <div style={{ marginTop: '10px' }}>
        <div className="image-container">
        {!isVideoEnded ? (
          <video width="100%" autoPlay muted 
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
          onEnded={handleVideoEnd}>
            <source src={ContactPage} type="video/mp4" className="background-image"/>
          </video>
          ) : (
            <img src={ContactMain} alt="ContactImg" 
            className="background-image" 
            width="100%" 
            onContextMenu={(e) => e.preventDefault()}/> 
          )}
        </div>
    </div>*/}

    <Row className="text-center mt-1">
        <Col>
          <h2> <span> Contact </span> &nbsp;Us </h2>
        </Col>
      </Row> 

    <Container className="contact-container mt-2">
      <Row className="justify-content-center align-items-center">
        {/* Left Side - Contact Form */}
        <Col md={6} xs={12} className="contact-form">
        <div className="form-card">
          <Form onSubmit={handleSubmit}>
            {error && <p className="text-danger">{error}</p>}

            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formPhone" className="mt-3">
              <Form.Label>Telephone</Form.Label>
              <PhoneInput
                country={'in'} // Default country code
                value={phone} // State to hold the phone number
                onChange={(phone) => setPhone(phone)} // Update phone state
                placeholder="Enter phone number"
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                isInvalid={error && phone.replace(/[^0-9]/g, '').slice(-10).length !== 10}
              />
            </Form.Group>

            <Form.Group controlId="formMessage" className="mt-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                maxLength={messageLimit}
                placeholder="Enter your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
               <Form.Text>
              {messageLimit - message.length} characters remaining
            </Form.Text>
            </Form.Group>

            <Button className="mt-4" variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </Col>

      
        {/* Right Side - Contact Info */}
        <Col md={6} xs={12} className="contact-info text-center">
          <div className="contact-card">
            {/* <div className="icon-circle mb-3">
             <a href="mailto:contact@vrjsolutions.com"><i className="fas fa-envelope fa-3x mail"></i></a> 
            </div> */}
            {/* <h2>Contact Us</h2> */}
            {/* <p>Let's Start Creating! Fill out the form on the left and send us a message.</p>
            <p>We look forward to hearing from you!</p> */}
             <h4 className="mt-4">VRJ Solutions Pte. Ltd.</h4>
            <h4 className="mt-4">7500A, Beach Road, #14-302,</h4>
            <h4 className="mt-4">The Plaza, Singapore - 199591</h4>
            <h4 className="mt-4">Call us : +65 91544181</h4>
            {/* <p className="contact-phone"> +65 91544181</p> */}
            <h3 className="mt-4">Email us : contact@vrjsolutions.com</h3>
            
          </div>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default Contact;
