import React from 'react';
import './Footer.css'; // Add custom CSS styling

const FooterPage = () => {
  return (
    <div className="footer-container mt-5 pt-5">
      
      {/* Centered Content */}
      <div className="footer-content text-center mt-3">
        <div className="social-icons">
          <a href="#" className="mx-2"><i className="fab fa-facebook fa-2x"></i></a>
          <a href="#" className="mx-2"><i className="fab fa-x-twitter fa-2x"></i></a>
          <a href="#" className="mx-2"><i className="fab fa-instagram fa-2x"></i></a>
          <a href="#" className="mx-2"><i className="fab fa-linkedin fa-2x"></i></a>
        </div>
        <p className="mt-3">
          ©2025 All Rights Reserved VRJ Solutions.<br/>
          Powered By <a href="https://www.architanz.com" target="_blank" rel="noopener noreferrer">Architanz Technologies..</a>
        </p>
      </div>
    </div>
  );
};

export default FooterPage;
